import { Add, Close, Remove } from '@mui/icons-material';
import { Dialog, IconButton, Skeleton, Slide } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addToCartApi } from '../../features/addToCartAfterLogin';
import { buyNowApi } from '../../features/buyNowSlice';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { baseUrl } from '../../index';
import RequiredProductModal from './RequiredProductModal';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const QuickViewNew = ({ open, toggleQuickView, ProductDetails }) => {

    const [itemQuantity, setItemQuantity] = useState(1)
    const [showRequiredProductModal, setShowRequiredProductModal] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isAuthenticated, uniqueUserId } = useSelector((state) => state.authData)



    const handleDecreseQuantity = async() => {
        if (Number(ProductDetails.minCartQuantity) === Number(itemQuantity)) {
            await setItemQuantity(Number(ProductDetails.minCartQuantity))

            await toast.error(`Minimum ${ProductDetails?.minCartQuantity} Product to buy`)
        } else {
            await setItemQuantity(itemQuantity - 1)
        }
    }
    const handleIncreseQuantity = async() => {
        if (Number(ProductDetails.maxCartQuantity) === Number(itemQuantity)) {
            await setItemQuantity(Number(ProductDetails.maxCartQuantity))
            await toast.error(`Allow Only ${ProductDetails?.maxCartQuantity} Product in Cart`)
        } else {
            await setItemQuantity(itemQuantity + 1);
        }
    }

    const handleAddToCart = (e,item, itemQuantity) => {
        e.preventDefault()
        if (item.requiredOtherProducts?.length) {
            setShowRequiredProductModal(true)
        } else {
                dispatch(addToCartApi({
                    productCode: item.productCode, requiredOtherProducts: [], itemQuantity
                }))
                    .then(async (response) => {
                        toast.success(response.payload.message)
                        dispatch(GetOverAllCountAPI())
                        // setIsLoading(false)
                        toggleQuickView(false)
                        setShowRequiredProductModal(false)
                    })
                    .catch((error) => {
                        toast.error('Some Error Occured, Please try after sometime');
                    });
        }
    }

    const handleBuyNow = (e, item, itemQuantity) => {
        e.preventDefault()
        if (isAuthenticated) {  
            const filteredData = [
                {
                    productCode: item.productCode,
                    Quantity: itemQuantity.toString(),
                    requiredOtherProducts: [],
                }
            ]
            localStorage.setItem('CartItemDetails', JSON.stringify(filteredData))
            navigate('/checkout')
        } else {
            navigate('/login')
        }
    }

    if (showRequiredProductModal) {
        return <RequiredProductModal
            showRequiredProductModal={showRequiredProductModal}
            setShowRequiredProductModal={setShowRequiredProductModal}
            Product={ProductDetails}
            toggleQuickView={toggleQuickView}
        />
    }


    return (
        <Dialog open={open} 
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth='lg'>
            <div className="quickview position-relative">
                <div className="row">
                    <div className="col-md-6">
                        <figure> 
                            {ProductDetails?.productImageUrl ?
                                <img
                                    src={ProductDetails?.productImageUrl}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = './images/Image_not_available.png';
                                    }} alt="ProductImage"
                                /> : <Skeleton height="100%" animation="wave" />
                            }
                        </figure>
                    </div>
                    <div className="col-md-6">
                        <div className="quickview_content">
                            <figcaption className='mb-2 mt-1'>
                                {ProductDetails?.categoryName}&nbsp;
                                {ProductDetails.isMarkAsNew && <span className=' newlaunch'>New Launch</span>}
                            </figcaption>
                            <h4 className='productName'> {ProductDetails?.productName}</h4> 
                            <p>{ProductDetails?.hSoldBy} : <b className='text-theme'>{ProductDetails?.soldBy}</b></p>
                            <div className="price-list mb-2">
                                <div>
                                    <span className="view_price">{ProductDetails?.sellingPrice}</span>
                                    {ProductDetails?.sellingPrice !== ProductDetails?.mrp && (
                                        <span className="view_regularprice">{ProductDetails?.mrp}</span>
                                    )}
                                </div>

                            </div>
                            <p className='mb-1 me-2'>Quantity :</p>
                            <div className="add_count me-4">
                                <IconButton onClick={handleDecreseQuantity} ><Remove fontSize='small' /></IconButton>
                                <span>{itemQuantity}</span>
                                <IconButton onClick={handleIncreseQuantity}><Add fontSize='small' /></IconButton>
                                {ProductDetails?.packs > "0" ?
                                    <p className='m-0 ms-3 mt-2'>(Packs of {ProductDetails?.packs})</p> : <></>
                                }
                            </div>
                            <div className="product_add_count d-flex align-items-center gap-2">
                                {ProductDetails.isOutOfStock === false ? <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={(e) => handleAddToCart(e,ProductDetails, itemQuantity)}  >Add to cart</button>
                                    : <button className='bg-primary text-white py-2 w-100 border-0 rounded disabledBTN'>Out Of Stock</button>}

                                {ProductDetails.isOutOfStock === false ? <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={(e) => handleBuyNow(e,ProductDetails, itemQuantity)}  >Buy Now</button>
                                    : <button className='bg-primary text-white py-2 w-100 border-0 rounded disabledBTN'>Buy Now</button>}
                            </div>
                            <h6>Product Details</h6>
                            <div className="table-responsive">
                                <table className="table table-sm table-borderless">
                                    <tbody>
                                        <tr>
                                            {ProductDetails?.shortDescription}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ cursor: "pointer" }} className="viewfull_details bg-light py-3 text-center d-block text-dark rounded" onClick={(e) => {
                                e.preventDefault()
                                window.open(`${baseUrl}${ProductDetails.searchEnginePageName}/${ProductDetails.productCode}`, '_blank');
                            }}>
                                <h6 className='m-0'>View Full Product Detail</h6>
                            </div>
                            <div className="close_btn position-absolute top-0 end-0">
                                <IconButton onClick={()=>toggleQuickView(false)}><Close /></IconButton>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </Dialog>
    )
}