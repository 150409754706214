import { Add, KeyboardBackspace, Remove } from '@mui/icons-material';
import { ButtonBase, IconButton, Skeleton, Tooltip, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { addToCartApi } from '../../features/addToCartAfterLogin';
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice';
import { AddtoWishListApi, RemoveFromWishListApi } from '../../features/wishListSlice';
import Loader from '../Common/Loader';
import { ApiCall } from '../Common/service';
import { v4 as uuidv4 } from 'uuid';



export const Cart = (props) => {
    const [loading, setLoading] = useState(false);
    const [allCartData, setAllCartData] = useState({})
    const [productItem, setProductItem] = useState([]);
    const [customerCartLoading, setCustomerCartLoading] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { uniqueUserId, user, isAuthenticated } = useSelector((state) => state.authData)

    const handleProductQuantityIncrease = async (product) => {
        if (Number(product.maxCartQuantity) === Number(product.quantity)) {
            await toast.error(`Allow Only ${product?.maxCartQuantity} Product in Cart`)
        } else {
            dispatch(addToCartApi({
                productCode: product.productCode,
                itemQuantity: (Number(product.quantity) + 1).toString()

            }))
                .then(async (response) => {
                    toast.success(response.payload.message)
                    await GetCartData()
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        }
    };
    const handleProductQuantityDecrease = async (product) => {
        if (Number(product.minCartQuantity) === Number(product.quantity)) {
            await toast.error(`Minimum ${product?.minCartQuantity} Product to buy`)
        } else {
            dispatch(addToCartApi({
                productCode: product.productCode,
                itemQuantity: (Number(product.quantity) - 1).toString()

            }))
                .then(async (response) => {
                    toast.success(response.payload.message)
                    await GetCartData()
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        }
    }

    //Get Data by API
    const GetCartData = async () => {
        setCustomerCartLoading(true)
        let data = await ApiCall(`/api/Web/GetCustomerCart`, {
            Token: user.token ? user.token : '',
            LoginUserCode: user.loginUserCode ? user.loginUserCode : '',
            CartToken: uniqueUserId ? uniqueUserId : localStorage.getItem('uniqueUserId'),
        })
        if (data.status === 'SUCCESS') {
            setAllCartData(data)
            setProductItem(data.cartList);
            setCustomerCartLoading(false)
            setLoading(true);
        } else if (data.status === 'ERROR') {
            setCustomerCartLoading(false)
            setLoading(true);
            setAllCartData({})
            setProductItem([]);
        }
    }

    const handleRemoveToCart = async (item) => {
        await Swal.fire({
            icon: 'warning',
            title: 'Do you want to remove this item ?',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove it!",
            cancelButtonText: "No",
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                RemoveCartData(item.productCode)
                dispatch(GetOverAllCountAPI())
            }
        });

    }

    const handleWishlist = (e, favourite, productCode) => {
        e.preventDefault()
        if (favourite) handleRemoveFromWishList(productCode)
        else handleAddWishlist(productCode)
    }
    const handleAddWishlist = (productCode) => {
        if (isAuthenticated) {
            dispatch(AddtoWishListApi(productCode))
                .then(async (response) => {
                    toast.success(response.payload)
                    dispatch(GetOverAllCountAPI())
                    await GetCartData()
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        } else {
            navigate('/login')
        }
    }

    const handleRemoveFromWishList = (productCode) => {
        if (isAuthenticated) {
            dispatch(RemoveFromWishListApi(productCode))
                .then(async (response) => {
                    toast.success(response.payload)
                    dispatch(GetOverAllCountAPI())
                    await GetCartData()
                })
                .catch((error) => {
                    toast.error('Some Error Occured, Please try after sometime');
                });
        } else {
            navigate('/login')
        }
    }

    const RemoveCartData = async (e) => {
        let data = await ApiCall(`/api/Web/RemoveItemFromCart`, {
            Token: user.token ? user.token : '',
            LoginUserCode: user.loginUserCode ? user.loginUserCode : '',
            productCode: e ? e : '',
            CartToken: uniqueUserId ? uniqueUserId : localStorage.getItem('uniqueUserId'),
        })
        if (data.status === 'SUCCESS') {
            toast.success(data.message)
            await GetCartData()
        } else if (data.status === 'ERROR') {
            toast.success(data.message)
        }
    }

    function formatDateToCustomString(date) {
        const padToTwoDigits = (num) => num.toString().padStart(2, '0');
        const day = padToTwoDigits(date.getDate());
        const month = padToTwoDigits(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = padToTwoDigits(date.getHours());
        const minutes = padToTwoDigits(date.getMinutes());
        const seconds = padToTwoDigits(date.getSeconds());

        return `${day}${month}${year}${hours}${minutes}${seconds}`;
    }

    const genrateCheckOutToken = () => {
        const newUserId = uuidv4();
        const now = new Date();
        const formattedDate = formatDateToCustomString(now);
        const CombineDateAndToken = formattedDate + `-` + newUserId
        const fourtyLength = CombineDateAndToken.substring(0, 40)
        return fourtyLength;
    };

    //For checking if Your Cart has some out of stock item
    const handleBuyClick = async () => {
        if (isAuthenticated) {
            for (const item of productItem) {
                if (item.isOutOfStock) {
                    toast.error("This is to inform you that some product you have added in Cart is temporarily out of stock. Please remove it then Proceed.");
                    return;
                }
            }
            const CheckOutTokenLocalStorage = localStorage.getItem('CheckOutToken');
            if (CheckOutTokenLocalStorage) {
                localStorage.removeItem('CheckOutToken')
                let NewCheckOutToken = genrateCheckOutToken()
                localStorage.setItem('CheckOutToken', NewCheckOutToken)
            } else {
                let NewCheckOutToken = genrateCheckOutToken()
                localStorage.setItem('CheckOutToken', NewCheckOutToken)
            }

            const filteredData = productItem?.map(product => ({
                productCode: product.productCode,
                Quantity: product.quantity,
                requiredOtherProducts: product?.requiredOtherProducts?.map(item => item.productCode) || [],
            }));

            localStorage.setItem('CartItemDetails', JSON.stringify(filteredData))
            navigate('/checkout')
        } else {
            navigate('/login')
        }
    }
    useEffect(() => {
        GetCartData();
    }, [])



    return (
        <>
            {customerCartLoading && <Loader /> }
            <section className='py-5' style={{ backgroundColor: '#f8f8f8' }}>
                <div className="container-fluid">
                    {productItem?.length > 0 ?
                        <div className="row">
                            <div className="col-md-8 col-xl-9 mb-3 mb-md-0">
                                <div className="cart_card">
                                    {
                                        loading ? productItem && <div className="cart_head border-bottom d-flex justify-content-between pb-3">
                                            <h4 className='mb-1'>Shopping Cart</h4>
                                            <b>Price</b>
                                        </div> :
                                            <Skeleton />
                                    }
                                    {loading ? productItem && productItem?.map((item, index) =>

                                        <div className='border-bottom'>
                                            <div className="cart_product py-3" key={`Products_Cart_${index}`}>
                                                <div onClick={() => navigate(`/products-details?productCode=${item.productCode}`)} style={{ cursor: "pointer" }}>
                                                    <figure className='figure m-0'>
                                                        <img src={item?.imgSrc || item?.productImageUrl} className="figure-img img-fluid" alt={`ProductImage_${index}`} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }} />
                                                    </figure>
                                                </div>
                                                <div className='product_info me-0'>
                                                    <div >
                                                        <div onClick={(e) => {
                                                            e.preventDefault();
                                                            navigate(`/${item.searchEnginePageName}/${item.productCode}`);
                                                        }} style={{ cursor: "pointer" }}>
                                                            <figcaption className="figure-caption text-start mb-2" >
                                                                {item.categoryName} {item.isMarkAsNew && <div className='newlaunch d-inline px-2 py-1 shadow-sm'>New Launch</div>}
                                                            </figcaption>
                                                        </div>
                                                        <Link to={`/${item.searchEnginePageName}/${item.productCode}`}><h6 className='productName w-75'>{item.productName}</h6></Link>
                                                        <div className="mt-2 d-flex gap-2 checkout_btn position-relative">
                                                            {
                                                                !item.isDisableWishlistBtn && <ButtonBase onClick={(e) => handleWishlist(e, item.favourite, item.productCode)}>
                                                                    <button className='btn btn-sm border' style={{ backgroundColor: item.favourite ? '#e4e4e4' : 'inherit' }}>
                                                                        <i className={`fa-${item.favourite ? 'solid' : 'regular'} fa-heart text-theme`}></i>
                                                                    </button>
                                                                </ButtonBase>
                                                            }

                                                            {!item.isOutOfStock && <div className="add_count">
                                                                <IconButton onClick={() => handleProductQuantityDecrease(item)}><Remove fontSize='small' /></IconButton>
                                                                <span>{item.quantity}</span>
                                                                <IconButton onClick={() => handleProductQuantityIncrease(item)}><Add fontSize='small' /></IconButton>
                                                            </div>}
                                                            <ButtonBase><button className='btn btn-sm btn-danger' onClick={() => handleRemoveToCart(item, index)}><i class="fa-regular fa-trash-can"></i></button>
                                                            </ButtonBase>
                                                        </div>
                                                    </div>
                                                    {!item.isOutOfStock ? <div className="text-md-end" style={{ cursor: "default" }}>
                                                        <div className="my-3 my-md-0">
                                                            <div className='fw-bold fs-5'>₹ {isNaN(Number(item.priceHighlight || item?.sellingPrice)) === true ? 0 : Number(item.priceHighlight || item?.sellingPrice).toFixed(2)}</div>
                                                            {
                                                                item.discount >= '1.00' &&
                                                                <>
                                                                    M.R.P.: <span className='priceCompare text-decoration-line-through text-black-50 fw-medium fs-6'>₹ {isNaN(Number(item.priceCompare || item?.mrp)) === true ? 0 : Number(item.priceCompare || item?.mrp).toFixed(2)}</span>  <span className='discount'>({item.discountType === "FLAT" && "₹ "}{item.discount}{item.discountType === "PERCENTAGE" && "%"} Off)</span>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="cart_price">
                                                            Total  Amount :  &nbsp; <span>₹ {(Number(item.priceHighlight || item?.sellingPrice) * item.quantity).toFixed(2)}</span>
                                                        </div>
                                                    </div> : <div> <h1 className='text-danger fs-4 mt-5'> Out of Stock </h1>  </div>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {item?.requiredOtherProducts?.length > 0 &&
                                                    <div className="cart_product border-bottom required-products-container mt-2">
                                                        <div className="products-list d-flex">
                                                            {item?.requiredOtherProducts?.map((product, index) => (
                                                                <div key={index} className="product-item d-flex" style={{ width: "-webkit-fill-available", gap: "10px", alignItems: "center" }}>
                                                                    <requiredFig className='requiredFig m-0'>
                                                                        <img src={product.productImageUrl} className="requiredFig-img img-fluid" alt={product.productName} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }} />
                                                                    </requiredFig>
                                                                    <div className="product_info">
                                                                        <div className="">
                                                                            <Tooltip arrow title={product.productName} TransitionComponent={Zoom} placement="top-start">

                                                                                <Link to={`/${product.seoFriendly}/${product.productCode}`} className='productName ' style={{ fontSize: "13px" }}>{product.productName}</Link>
                                                                            </Tooltip>
                                                                            <p style={{ fontSize: "10px" }}>Brand: {product.brandName}</p>
                                                                            <p style={{ fontSize: "10px" }}>Price: ₹{product.sellingPrice}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    )
                                        :
                                        (() => {
                                            const slides = [];
                                            for (let i = 0; i < 4; i++) {
                                                slides.push(
                                                    <div className="cart_product py-3 border-bottom" key={`CartSkeleton_${i}`} >
                                                        <Skeleton variant="rectangular" width={160} height={160} />
                                                        <div className='product_info me-0'>
                                                            <div className="w-50">
                                                                <Skeleton className='mb-2' />
                                                                <Skeleton className='mb-2' />
                                                                <Skeleton width="60%" />
                                                            </div>
                                                            <div className="" style={{ width: '15%' }}>
                                                                <Skeleton className='mb-2' />
                                                                <Skeleton className='mb-2' />
                                                                <Skeleton className='mb-2' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return slides;
                                        })()
                                    }

                                    {loading ?
                                        <div className="total_price pt-3 text-end" style={{ cursor: "default" }}>
                                            <h5>Subtotal ({allCartData?.totalItem} item): <span>₹ {Number(allCartData?.totalSellingPrice).toFixed(2)}</span></h5>
                                        </div> :
                                        <div className="total_price pt-3 text-end">
                                            <Skeleton sx={{ ml: 'auto' }} width="20%" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 col-xl-3">
                                {loading ?
                                    <div className="cart_card summary">
                                        <div className="cart_head border-bottom pb-2">
                                            <h4 className='mb-1'>Summary</h4>
                                        </div>
                                        <div className="table-responsive" style={{ cursor: "default" }}>
                                            <table className="table table-sm table-borderless" >
                                                <tbody>
                                                    <tr className="">
                                                        <td className='fs-6 fw-bold'>ITEMS TOTAL ({allCartData?.totalItem})</td>
                                                        <td className='fs-6 fw-bold'>₹ {allCartData?.totalSellingPrice}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td> Total MRP</td>
                                                        <td className='priceCompare text-decoration-line-through text-black-50 fw-medium fs-6 fw-bold'>₹ {allCartData?.totalMRPAmount}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-success">Savings</td>
                                                        <td className="text-success">₹ {allCartData?.savingAmount}</td>
                                                    </tr>
                                                    {/*<tr className="">*/}
                                                    {/*    <td>Shipping</td>*/}
                                                    {/*    <td className="text-success">{allCartData?.shipping ? `₹ ${allCartData?.shipping}` : "FREE SHIPPING"}</td>*/}
                                                    {/*</tr>*/}
                                                    <tr className="">
                                                        <td>Tax</td>
                                                        <td>₹ {allCartData?.taxAmount}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className='fs-6 fw-bold'>Payable Amount</td>
                                                        <td className='fs-6 fw-bold'>₹ {allCartData?.totalPayableAmount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <ButtonBase className='w-100' onClick={() => handleBuyClick()} ><button className='bg-primary w-100 py-2 text-white border-0 rounded mybtn' >Proceed to Buy</button></ButtonBase>
                                    </div> :
                                    <div className="cart_card summary">
                                        <div className="cart_head border-bottom pb-2">
                                            <h4 className='mb-1'><Skeleton /></h4>
                                        </div>
                                        <div className="table-responsive" >
                                            <table className="table table-sm table-borderless" >
                                                <tbody>
                                                    <tr className="">
                                                        <td className='fs-6 fw-bold'><Skeleton /></td>
                                                        <td className='fs-6 fw-bold'><Skeleton /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-danger"><Skeleton /></td>
                                                        <td className="text-danger"><Skeleton /></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td><Skeleton /></td>
                                                        <td className="text-danger"><Skeleton /></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td><Skeleton /></td>
                                                        <td><Skeleton /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Skeleton height='250' />
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <div className="container empty_cart">
                                <div className="row justify-content-center">
                                    <div className="col-md-5 text-center">
                                        <img src="./images/cart_empty.svg" alt="" />
                                        <h3 className='fw-bold text-theme mt-3 mb-0'>Your Cart is Empty !</h3>
                                        <p className='my-4'>Add items to your cart as you browse and they will magically appear here.</p>
                                        <div className="buttons-group text-center">
                                            <Link to='/' className='bg-primary text-white py-3 px-4 border-0 rounded mybtn'><KeyboardBackspace /> Browse Our Catalog</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section >
        </>
    )
}
