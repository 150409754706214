import { WhatsApp } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { CTA } from './CTA'
import { useSelector } from 'react-redux'




export const Footer = () => {
    const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
    const { categoryList } = useSelector((state) => state.commonApiData.getCategoryCart)

    return (
        <>
            <CTA />

            <footer>
            <div className="container">
                <div className="foot-icons py-5 border-1 border-bottom">
                    <div className="row">
                        <div className="col-lg-3 mb-3 mb-md-0 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/shipping.svg" width="35" alt="" /></span>
                            <p className="m-0 mt-2"> Free Shipping over Rs 200  </p>
                        </div>
                        <div className="col-lg-3 mb-3 mb-md-0 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/return.svg" width="35" alt="" /></span>
                            <p className="m-0 mt-2 ">  Cancellation after 1 day  </p>
                        </div>
                        <div className="col-lg-3 col-6 text-center border-1 border-end">
                            <span className="me-3"><img src="./images/icons/secure.svg" width="35" alt="" /> </span>
                            <p className="m-0 mt-2 ">  100% Secure Payment  </p>
                        </div>
                        <div className="col-lg-3 col-6 text-center">
                            <span className="me-3"><img src="./images/icons/24-hour.svg" width="35" alt="" /> </span>
                            <p className="m-0 mt-2 ">  24/7 Dedicated Support  </p>
                        </div>
                    </div>
                </div>
                <div className="foot-content">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="foot-info">
                                <h6 className="fw-bold fs-3 text-theme text-capitalize">indocraftworld.com</h6>
                                {/* <p>Founded with a vision to promote “Vocal for Local” campaign and to transform and scale up the entire structure of the business for our people deeply involved with Arts and Crafts of India</p> */}
                                <span className='my-2 fs-6 fw-bold d-block'>Experience Our App On Mobile</span>
                                    <div className="d-flex">
                                        <a
                                            className='mx-3 text-dark text-decoration-none small'
                                            href="https://play.google.com/store/apps/details?id=com.sipl.indo_craft_world"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src="./images/google_play.png" className='w-100' height='60' alt="" />
                                        </a>
                                    <Link to="">
                                        <img src="./images/apple_store.png" className='w-100' height='60' alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>  
                        <div className="col-lg-2 ">
                            <div className="links-item mt-5 mt-lg-0">
                                <h6 style={{
                                    margin: " 10px 0px"
                                }} className="fsz-18 "> Company </h6>
                                <ul className="links">
                                    <li> <Link to="/about-us"> About</Link> </li>
                                    <li> <Link to="/contact"> Contact</Link> </li>
                                {/*    <li> <Link to="/career">Career </Link> </li>*/}
                                    <li> <Link to="/blogs"> Blog </Link> </li>
                                    {/*<li> <Link to="/sitemap"> Sitemap </Link> </li>*/}
                                    {/*<li> <Link to="/store-location"> Store Locations </Link> </li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="links-item mt-5 mt-lg-0">
                                <h6 style={{
                                    margin: " 10px 0px"
                                }} className="fsz-18"> Help Center </h6>
                                <ul className="links">
                                    <li> <Link to="/cancellation-policy"> Cancellation Policy</Link> </li>
                                    <li> <Link to="/privacy-policy"> Privacy Policy</Link> </li>
                                    <li> <Link to="/return-policy"> Return and Refund Policy </Link> </li>
                                    <li> <Link to="/terms-conditions"> Terms & Conditions </Link> </li>
                                    <li> <Link to="/faqs"> FAQs </Link> </li>
                                    <li><Link to='/profile'>  My Account  </Link> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <small className="fsz-12 text-uppercase"> hotline 24/7 </small>
                            <h5 className="fw-bold color-blue1 d-flex align-items-center mt-2"><WhatsApp sx={{ mr: '5px' }} color='success' /> 9971930718 </h5>
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="fa-regular fa-envelope text-theme"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                        <a className='text-decoration-none text-dark mb-2' href="mailto:icw@indocraftworld.com"> icw@indocraftworld.com</a>
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <div className="flex-shrink-0">
                                    <i className="fa-solid fa-location-dot text-theme"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <p className='small'>BASEMENT, KH NO-101, BLOCK-L, MAHIPALPUR EXTN, New Delhi, New Delhi, Delhi, 110037</p>
                                </div>
                            </div>
                            <div id="socialMediaicons">
                                <a href="#" className='me-2'><img src="./images/instagram.png" /></a>
                                <a href="#" className='me-2'><img src="./images/facebook.png" /></a>
                                <a href="#" className='me-2'><img src="./images/linkedin.png" /></a>
                                <a href="#" className='me-2'><img src="./images/twitter.png" width='30' /></a>
                            </div>
                        </div>
                        <div className="col-12 mt-4 footer_about border-top">
                            <div className="accordion mt-2" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingabout">
                                            <button className="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseabout" aria-expanded="true" aria-controls="collapseabout">
                                            More About Indocraft World
                                        </button>
                                    </h2>
                                    <div id="collapseabout" className="accordion-collapse collapse" aria-labelledby="headingabout" data-bs-parent="#accordionExample" >
                                        <div className="accordion-body">
                                            <h6>Indocraft: Affordable Online Craft Shopping for Your Home Decor</h6>
                                            <p>Shopping online offers numerous benefits. You can browse at your leisure and explore various options to find precisely what you need. Comparing prices is effortless, enabling you to get the best deals. With Indocarft World, you can shop for anything at the lowest prices available. Whether you're looking for cool gifts for friends and family or something for yourself, you'll find plenty of choices on the Internet.</p>

                                            <h6>Download the Indocraft World App for Hassle-Free Online Shopping</h6>
                                            <p>For a seamless shopping experience, consider downloading the Indocraft World app. While you might think an app isn't necessary for shopping, Indocraft World offers plenty of features that will make your life easier.</p>

                                            <Link to='/'><h6 style={{fontSize:'18px'}} className='text-theme'>Online Shopping</h6></Link>

                                            <ul className="links li-cat p-0">
                                                    {
                                                        categoryList?.map((category, index) => (
                                                            <li key={index} className=''>
                                                            <Link to={`/products?category=${category.categoryName}`} style={{ fontSize: '13px' }} className='mdi_heading text-dark fw-bold footer_Link'>{category.categoryName}</Link>
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    category?.subCategoryList?.map((subCategory) => (
                                                                        <Link to={`/products?subCategory=${subCategory.subCategoryName}`} className='me-2 text-theme fw-medium'>{subCategory.subCategoryName} <span>&nbsp; | </span></Link>
                                                                    ))
                                                                }
                                                            </div>
                                                        </li>
                                                    ))
                                                }                                                   
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </footer>
            <div className="foot_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 text-center text-lg-start my-auto d-flex gap-2">
                            <Link to="/sellerRegistration" className="br-20 fw-bold text-theme text-decoration-none becomeseller">Become a Seller</Link>
                            <Link className='br-20 fw-bold text-theme text-decoration-none becomeseller' style={{ cursor: "pointer" }}
                                onClick={() => window.open('https://seller.indocraftworld.com/', '_blank')}
                            >Seller Login</Link>
                        </div>
                        <div className="col-lg-3 text-center my-auto">
                            <p className="m-0 text-black-50"> © 2024 <Link to="/" className="fw-bold text-theme text-decoration-none opacity-50"> Indocraft </Link>. All Rights Reserved </p>
                        </div>
                        <div className="col-lg-3 text-center my-auto">
                            <p className="m-0 text-black-50"> Powered By: <Link to="https://www.sagarinfotech.com/" target='_blank' className="fw-bold text-theme text-decoration-none opacity-50"> Sagar informatics Pvt. Ltd.</Link></p>
                        </div>
                        <div className="col-lg-3">
                            <div className="pay text-center my-4 my-lg-0">
                                <img src="./images/payment-icons.png" alt="" className="mx-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
