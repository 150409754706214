import { Apps, FormatListBulleted, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ButtonBase, IconButton, Pagination, PaginationItem, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Card from './Card';
import { Filter } from './Filter';
import { ApiCall } from './service';
import { useSelector } from 'react-redux';



export const Product = () => {
    const productList = useRef(null)
    let url = window.location.search
    const queryString = new URLSearchParams(url);
    const categories = queryString.get('category');
    const brands = queryString.get('brand');
    const priceRange = queryString.get('price');
    const sortedBy = queryString.get('sortBy');
    const section = queryString.get('section');
    const subCategory = queryString.get('subCategory');
    const searchingByUrl = queryString.get('searchingText');
    const pageIndexByUrl = queryString.get('pageIndex');
    const storeProducts = queryString.get('store');
    const discountPercentage = queryString.get('discount');
    const [showShortby, setShowShortby] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [horizontalView, setHorizontalView] = useState(() => {
        const storedValue = localStorage.getItem('isOpen');
        return storedValue !== null ? JSON.parse(storedValue) : false;
    })
    const [allProductData, setAllProductData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [filterDataList, setFilterDataList] = useState({})
    const [price, setPrice] = useState([])
    const [brandfilter, setBrandFilter] = useState('')
    const [categoryfilter, setCategoryFilter] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [bannerImage, setBannerImage] = useState('');
    const [storeName, setStoreName] = useState('');
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);
    const [PageIndex, setPageIndex] = useState(1);
    const [PageSize, setPageSize] = useState(1);
    const [totalProductCount, setTotalProductCount] = useState(1);
    const countNo = Math.ceil(parseInt(totalProductCount ? totalProductCount : 1) / parseInt(PageSize ? PageSize : 1));
    const [priceRangeLoading, setPricerangeLoading] = useState(true)
  
    const { categoryList } = useSelector((state) => state.commonApiData.getCategoryCart)
    const { isAuthenticated, user } = useSelector((state) => state.authData)


    const sortByData = [`Featured`, `Price: Low to High`, `Price: High to Low`, `Avg. Customer Review`, `Newest Arrivals`]
    // Calculate the product Count
    const startItem = (parseInt(PageIndex) - 1) * parseInt(PageSize) + 1;
    const endItem = Math.min(startItem + parseInt(PageSize) - 1, totalProductCount);

    var timeout;
    const handleChangePriceRangeCommitted = async (event, newPrice) => {
        event.preventDefault()
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            const existingParams = new URLSearchParams(location.search);
            existingParams.set('price', newPrice);
            const newUrl = { pathname: location.pathname, search: existingParams.toString() };
            navigate(newUrl);
            setPrice(newPrice);
        }, 100);
    }
    const handleChangePriceRange = (event, newPrice) => {
        setPrice(newPrice);
    }
    const handlePageChange = async (event, newPageIndex) => {
        const spaceOnTop = 120;
        const productDiv = productList.current;
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('pageIndex', newPageIndex);
        const newUrl = { pathname: location.pathname, search: existingParams.toString() };
        await navigate(newUrl);
        await setPageIndex(newPageIndex);
        if (productDiv) {
            productDiv.focus();
            const topPosition = productDiv.getBoundingClientRect().top + window.pageYOffset - spaceOnTop;
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    };

    //For Clear the all filter
    const handleClear = async () => {
        await navigate('/products');
        setBrandFilter([]);
        setCategoryFilter([]);
    };

    const handleChangeCategoryClick = async (item) => {
        if (item.subCategoryName) navigate(`/products?subCategory=${item.subCategoryName}`)
        else navigate(`/products?category=${item.categoryName}`)
    }

    const handleChangeBrandClick = async (e, item) => {
        const existingParams = new URLSearchParams(location.search);
        if (e.target.checked === true) {
            const data = [...brandfilter, item.brandName]
            existingParams.set('brand', data);
            const newUrl = { pathname: location.pathname, search: existingParams.toString() };
            await navigate(newUrl);
            await setBrandFilter(data)
        } else {
            const data = brandfilter?.filter((brand) => brand !== item.brandName)
            existingParams.set('brand', data);
            const newUrl = { pathname: location.pathname, search: existingParams.toString() };
            await navigate(newUrl);
            await setBrandFilter(data)
        }
    }

    //for All Product List
    const GetAllProductData = async (FilterData) => {
        let data = await ApiCall(`/api/Web/GetProductList`, { LoginUserCode: user.loginUserCode ? user.loginUserCode : '', Token: user.token ? user.token :'', ...FilterData })
        if (data.status === 'SUCCESS') {
            await setPageSize(data.pageSize)
            await setTotalProductCount(data.productCount)
            await setBannerImage(data.bannerImageURL)       
            await setLoadingSkeleton(false)
            await setStoreName(data.storeName)       
            return data.products
        } else {
            await setPageSize(0)
            await setTotalProductCount(0)       
            await setBannerImage('')
            await setLoadingSkeleton(false)
        }
    }


    //Get Fitler Catagory Data
    const GetFilterData = async (RequestData) => {
        let data = await ApiCall(`/api/Web/GetFiltersData`, { ...RequestData })
        if (data.status === 'SUCCESS') {
            await setFilterDataList(data) 
            await setPricerangeLoading(false)  
        } else if (data.status === 'ERROR') {
            await setFilterDataList({})
            await setPricerangeLoading(false)
        }
    };

    useEffect(() => {
        localStorage.setItem('isOpen', JSON.stringify(horizontalView));
    }, [horizontalView]);


    useEffect(() => {
        setPrice([Number(filterDataList?.minPrice) || 0, Number(filterDataList?.maxPrice)] || 2000)
        categories ? setCategoryFilter([categories.includes(',') === true ? categories.split(',') : categories].flat()) : setCategoryFilter([]);
        brands ? setBrandFilter([brands.includes(',') === true ? brands.split(',') : brands].flat()) : setBrandFilter([]);
        priceRange ? setPrice(priceRange.includes(",") === true ? priceRange.split(",") : [Number(filterDataList?.minPrice) || 0, Number(filterDataList?.maxPrice) || 0]) : setPrice([Number(filterDataList?.minPrice) || 0, Number(filterDataList?.maxPrice) || 0]);
        sortedBy ? setSortBy(sortedBy) : setSortBy(sortByData[0]);
        pageIndexByUrl ? setPageIndex(pageIndexByUrl) : setPageIndex(1);


        const products = async () => {
            await GetFilterData({
                Category: categories ? [categories.includes(',') === true ? categories.split(',') : categories].flat().join(',') : '',
                SectionName: section ? section : "",
                SubCategory: subCategory ? subCategory : "",
                Discount: discountPercentage ? discountPercentage.toString() : '',
                vendorCode: storeProducts ? storeProducts.toString() : ""
            })

            let data = await GetAllProductData({
                Category: categories ? [categories.includes(',') === true ? categories.split(',') : categories].flat().join(',') : '',
                Brand: brands ? [brands.includes(',') === true ? brands.split(',') : brands].flat().join(',') : "",
                MinPrice: priceRange ? priceRange.includes(",") === true ? priceRange.split(",")[0].toString() : '' : '',
                MaxPrice: priceRange ? priceRange.includes(",") === true ? priceRange.split(",")[1].toString() : '' : '',
                SortBy: sortedBy ? sortedBy : '',
                SearchingText: searchingByUrl ? searchingByUrl : "",
                Section: section ? section : "",
                SubCategory: subCategory ? subCategory : "",
                PageIndex: pageIndexByUrl ? pageIndexByUrl.toString() : '1',
                Discount: discountPercentage ? discountPercentage.toString() : "",
                vendorCode: storeProducts ? storeProducts.toString() : ""
            })
            setAllProductData(data)
        }
        products()
    }, [categories, subCategory, brands, priceRange, sortedBy, section, searchingByUrl, filterDataList.minPrice, PageIndex]);


    return (
        <>
            <section className='pt-3 pb-3 pb-md-5' style={{ backgroundColor: '#f8f8f8' }}>
                <div className="container">
                    <nav className="breadcrumb align-items-center" ref={productList}>
                        <Link className="breadcrumb-item" to='/'>Home</Link>
                        <span>/</span>
                        <Link className="breadcrumb-item active" to='/products'>Products</Link>    
                    </nav>
                    {storeProducts && < div className='m-0 my-3 p-0  storeTextWithImage' style={{ height: "15rem" }}>
                        <img src='./images/store-banner.jpg' className='w-100 h-100' alt='' />
                        <span className='storesNameFooter'>{storeName}</span>
                    </div>}

                    <div className="row">
                        <div className="col-lg-4  col-xl-3">
                            <Filter
                                openFilter={openFilter}
                                setOpenFilter={setOpenFilter}
                                filterDataList={filterDataList}
                                handleClear={handleClear}
                                price={price}
                                setPrice={setPrice}
                                handleChangeCategoryClick={handleChangeCategoryClick}
                                handleChangePriceRange={handleChangePriceRange}
                                handleChangePriceRangeCommitted={handleChangePriceRangeCommitted}
                                handleChangeBrandClick={handleChangeBrandClick}
                                brandfilter={brandfilter}
                                categoryfilter={categoryfilter}
                                categoryListData={categoryList}
                                priceRangeLoading={priceRangeLoading }
                            />
                        </div>
                        <div className="col-lg-8 col-xl-9">
                            <div className="filter_head d-md-flex align-items-center justify-content-between" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 15px 64px 0px' }}>
                                <div className="heading mb-3 mb-md-0 d-flex justify-content-between align-items">
                                    <div className='d-md-flex' style={{ gap: '50px', alignItems: "center" }}>
                                        <h4 className='mt-md-0'><b className="text-theme">All</b> Products </h4>
                                        {!loadingSkeleton ? (Number(totalProductCount) > 0 && < span className='text-bold'> Showing {startItem} – {endItem} of {totalProductCount} results  </span>) : <Skeleton width='200px' />}
                                    </div>
                                    <button className='bg-primary text-white py-1 px-4 border-0 d-md-none rounded btn-sm mybtn' onClick={() => setOpenFilter(!openFilter)}>Filters</button>
                                </div>
                                <div className="filter-top-left d-flex align-items-center justify-content-between justify-content-md-end">
                                    <div className="sortby">
                                        <ButtonBase> <span onClick={() => setShowShortby(!showShortby)}>Sort by: {sortBy} {showShortby ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</span></ButtonBase>
                                        <div className={`sortby_drpdwn ${showShortby ? 'show' : 'hide'}`}>
                                            {sortByData?.map((item, index) => <button key={`sortByData${index}`} onClick={async () => {
                                                const existingParams = new URLSearchParams(location.search);
                                                existingParams.set('sortBy', item);
                                                const newUrl = { pathname: location.pathname, search: existingParams.toString() };
                                                await navigate(newUrl);
                                                await setSortBy(item)
                                                await setShowShortby(false)
                                            }} >{item}</button>)}
                                        </div>
                                    </div>
                                    <div className="view">
                                        <span>View</span>
                                        <IconButton onClick={() => setHorizontalView(!horizontalView)}> {horizontalView ? <Apps /> : <FormatListBulleted />}</IconButton>
                                    </div>
                                </div>
                            </div>
                            {!storeProducts ? !loadingSkeleton && bannerImage
                                ? <img src={bannerImage ? bannerImage : ''}
                                    className="figure-img img-fluid  w-100 rounded"
                                    alt=""
                                    onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available_ads.png'; }}
                                /> :
                                loadingSkeleton && !bannerImage ? <Skeleton animation="wave" /> : <></> : <></>
                            }
                            <div>
                                {
                                    loadingSkeleton ? <>
                                        {
                                            horizontalView ? <div className="row">
                                                {(() => {
                                                    const slides = [];
                                                    for (let i = 0; i < 12; i++) {
                                                        slides.push(
                                                            <div className="col-12" key={`product_item${i}`}>
                                                                <div className='mt-4'>
                                                                    <Skeleton animation="wave" style={{ height: "220px" }} />
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return slides;
                                                })()}
                                            </div> : <div className="row">
                                                {(() => {
                                                    const slides = [];
                                                    for (let i = 0; i < 12; i++) {
                                                        slides.push(
                                                            <div className="col-md-4 col-lg-4 col-6" key={`product_item${i}`}>
                                                                <div className='mt-4'>

                                                                    <Skeleton animation="wave" style={{ height: "25rem" }} />
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return slides;
                                                })()}
                                            </div>
                                        }
                                    </> :
                                        <>
                                            {
                                                totalProductCount >= "1" ?
                                                <div className="product_list" >
                                                    <div className='row px-2 px-md-0'>
                                                        {allProductData?.map((item, index) =>
                                                            <div className={`pb-3 position-relative ${horizontalView ? 'col-12' : 'col-md-4 col-xl-4 col-6'}`} key={`product_item${index}`}>
                                                                <Card Source={ 'Product'} item={item} index={index} horizontalView={horizontalView} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                    :
                                                    <div className='row' style={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center", marginTop: '50px' }}>
                                                        <div className='col-md-7 text-center'>
                                                            <img src='./images/product_not_found.png' alt='shopping cart' />
                                                            <h3>Product Not Found</h3>
                                                            <h5 className='text-black-50'>Please check the spelling or try searching for something else</h5>
                                                        </div>
                                                </div>
                                            }
                                        </>
                                }
                                {countNo > 1 && < Pagination
                                    className='pt-2 mt-3 mypagination'
                                    count={countNo}
                                    variant="outlined"
                                    page={Number(PageIndex)}
                                    onChange={handlePageChange}
                                    shape="rounded"
                                    size="large"

                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{
                                                previous: () => <><i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Previous </>,
                                                next: () => <>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"> </i></>
                                            }}
                                            {...item}
                                        />
                                    )}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}
