import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { ApiCall } from '../Common/service';
import { useEffect } from 'react';

export const BlogDetails = () => {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const paramValue = query.get('id');

    const GetBlogsData = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetDescriptionDetails`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'web',
                ID: paramValue ? paramValue : ''
            } : { Token: "", LoginUserCode: "", Source: 'web', ID: paramValue ? paramValue : '' }
        )

        if (data.status === 'SUCCESS') {
            setBlogData(data)
            setLoading(false)
        } else if (data.status === 'ERROR') {
            setBlogData([])
            setLoading(false)
        }
    }
    useEffect(() => {
        GetBlogsData();
    }, []);
  return (
    <>
        <section className='p-0'>
            <div className="container">
                <div className="contact_head py-5" style={{backgroundImage:'unset'}}>
                    <h1 className='text-dark text-center'>Blogs Details</h1>
                    <nav className="breadcrumb align-items-center justify-content-center">
                        <Link className="breadcrumb-item text-dark" to='/'>Home</Link>
                        <span className='text-dark'>/</span>
                        <Link className="breadcrumb-item text-dark" to='/'>Blogs</Link>
                        <span className='text-dark'>/</span>
                        <Link className="breadcrumb-item text-dark active" to='/blogsdetails'>Blogs Details</Link>
                    </nav>
                </div>
                
                  <div className="row">
                      <div className="col-md-8">
                          <div className="blogs_details">
                              <img src={blogData.imageURL} alt="" />
                              <div className="blog_date mt-3 d-flex justify-content-between align-items-center">
                                  {/*             <figcaption class="figure-caption text-start my-2">Electronic</figcaption>*/}
                                  <span className='fw-bold text-black-50 d-flex justify-content-end'>{blogData.createdDate} by <a className="text-theme2 fs-6" href="/blogs">{blogData.createdBy}</a></span>
                              </div>
                              <div className='fw-bold fs-2'>{blogData.description}</div>
                              <p className='text-black-50 fsz-18'>{blogData.descriptionDetails}</p>
                          </div>
                      </div>
                    {/*<div className="col-md-4">*/}
                    {/*    <div className="card p-3 bg-light border-0">*/}
                    {/*        <h4 className='pb-3 border-bottom'>Latest Post</h4>*/}
                    {/*        <div class="d-flex mb-3">*/}
                    {/*            <div class="flex-shrink-0">*/}
                    {/*                <img src="./images/essential1.jpg" alt="" width="120" />*/}
                    {/*            </div>*/}
                    {/*            <div class="flex-grow-1 ms-3">*/}
                    {/*                <Link class="mt-0 fs-5 mb-3 d-block">Panels with websites city connections</Link>*/}
                    {/*                <p><i class="fa-regular fa-calendar-days text-theme me-2"></i> 31 May 2022</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="d-flex mb-3">*/}
                    {/*            <div class="flex-shrink-0">*/}
                    {/*                <img src="./images/essential1.jpg" alt="" width="120" />*/}
                    {/*            </div>*/}
                    {/*            <div class="flex-grow-1 ms-3">*/}
                    {/*                <Link class="mt-0 fs-5 mb-3 d-block">Panels with websites city connections</Link>*/}
                    {/*                <p><i class="fa-regular fa-calendar-days text-theme me-2"></i> 31 May 2022</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="d-flex mb-3">*/}
                    {/*            <div class="flex-shrink-0">*/}
                    {/*                <img src="./images/essential1.jpg" alt="" width="120" />*/}
                    {/*            </div>*/}
                    {/*            <div class="flex-grow-1 ms-3">*/}
                    {/*                <Link class="mt-0 fs-5 mb-3 d-block">Panels with websites city connections</Link>*/}
                    {/*                <p><i class="fa-regular fa-calendar-days text-theme me-2"></i> 31 May 2022</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                            
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    </>
  )
}
