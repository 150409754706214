import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApiCall } from '../Common/service';

export const Blogs = () => {
    const [blogData, setBlogData] = useState([]);
    const [description, setDescription] = useState([]);
    const [loading, setLoading] = useState(false)
    const { user, isAuthenticated } = useSelector((state) => state.authData)
    const authLocalStorage = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : [];

    const GetBlogsData = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/GetBlogData`,
            isAuthenticated ? {
                Token: user.token ? user.token : authLocalStorage.authToken,
                LoginUserCode: user.loginUserCode ? user.loginUserCode : authLocalStorage?.userCode,
                Source: 'web'
            } : { Token: "", LoginUserCode: "", Source: 'web' }
        )

        if (data.status === 'SUCCESS') {
            setBlogData(data.getBlogRecords)
            setLoading(false)
        } else if (data.status === 'ERROR') {
            setBlogData([])
            setLoading(false)
        }
    }

    useEffect(() => {
        GetBlogsData();
    }, []);

    return (
        <>
            <section className='p-0 bg-light'>
                <div className="container">
                    <div className="contact_head">
                        <h1>Our Blogs</h1>
                        <nav className="breadcrumb align-items-center">
                            <Link className="breadcrumb-item text-white" to='/'>Home</Link>
                            <span className='text-white'>/</span>
                            <Link className="breadcrumb-item text-white active" to='/profile'>Blogs</Link>
                        </nav>
                    </div>

                    <div className="blogs_grid my-4">
                        <div className="row">
                            {blogData.map((item) =>
                                <div className="col-md-4 mb-3 mb-md-4" key={item.id}>
                                    <div className="card border-0 shadow-sm">
                                        <div className="bg_image">
                                            <Link to="/blogsdetails">
                                                <img className='w-100' src={item.imageURL} onError={(e) => { e.target.onerror = null; e.target.src = './images/Image_not_available.png'; }} alt="" />

                                            </Link>
                                        </div>
                                        <div className="bg_content p-3" style={{ minHeight:'195px' }}>
                                          {/*  <figcaption class="figure-caption text-start my-2">{item.blogCat}</figcaption>*/}
                                            <Link className='fw-bold fs-5'to={`/blogsdetails?id=${item?.id}`}>{item.description}</Link>
                                            <div className="blog_date mt-3">
                                                <span className='fw-bold text-black-50 d-flex justify-content-end'>{item.createdDate.split(' ')[0]} by <a className="text-theme2 fs-6 ms-2" href="">{item.createdBy.split(':')[1]}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
